import { VStack, HStack, Box } from "@chakra-ui/react";
import {
  ColorIcon,
  colors,
  ClickEventType,
  getCdnImageSrc,
  NextLink,
  //   responsive,
  Spacing,
} from "goi_common";

export const FuneralPrepareBannerSection = ({ path }: { path: ClickEventType["path"] }) => {
  return (
    <VStack css={{ padding: "30px 0px" }}>
      <VStack
        alignItems="flex-start"
        justifyContent="flex-start"
        css={{ margin: "40px 0px 12px 40px", width: "100%", boxSizing: "border-box" }}
      >
        <div className={"subtitle_21_sb"} style={{ textAlign: "left" }}>
          장례 준비가 처음이라면
          <br /> 꼭 읽어보세요
        </div>
      </VStack>
      <FuneralPrepareOneBanner path={path} />
      <Spacing gap={12} />
      <FuneralPrepareTwoBanner path={path} />
    </VStack>
  );
};

export const FuneralPrepareOneBanner = ({ path }: { path: ClickEventType["path"] }) => {
  return (
    <NextLink
      href="/funeral-prepare/1"
      event={{
        newGtm: { path, trigger_and_target: "content-uv_prepare_1" },
      }}
      css={{
        margin: "0 20px",
        padding: "36px 24px",
        width: "calc(100% - 40px)",
        boxSizing: "border-box",
        borderRadius: "10px",
        backgroundImage: `url(${getCdnImageSrc("platform/guidebook/prepare-image.png")})`,
        backgroundSize: "cover",
        cursor: "pointer",
        display: "block",
      }}
    >
      <VStack alignItems="flex-start">
        <div className="subtitle_18_sb white">
          처음하는 장례 준비 <br /> 어떻게 해야 후회 없을까요?
        </div>
        <Spacing gap={12} />
        <HStack className="subtitle_16_b orange650">
          <div>장례 준비의 A to Z</div>
          <Box>
            <ColorIcon name="icon-arrow-right-small-mono" color={colors.orange650} />
          </Box>
        </HStack>
      </VStack>
    </NextLink>
  );
};

export const FuneralPrepareTwoBanner = ({ path }: { path: ClickEventType["path"] }) => {
  return (
    <NextLink
      href="/funeral-prepare/2"
      event={{
        newGtm: { path, trigger_and_target: "content-uv_prepare_2" },
      }}
      css={{
        margin: "0 20px",
        padding: "36px 24px",
        width: "calc(100% - 40px)",
        boxSizing: "border-box",
        borderRadius: "10px",
        backgroundImage: `url(${getCdnImageSrc("platform/home/carousel-2.png")})`,
        backgroundSize: "cover",
        cursor: "pointer",
        display: "block",
      }}
    >
      <VStack alignItems="flex-start">
        <div className="subtitle_18_sb white">
          선불? 후불? <br /> 나에게 맞는 서비스 찾기
        </div>
        <Spacing gap={12} />
        <HStack className="subtitle_16_b orange650">
          <div>후불/선불 비교하기</div>
          <Box>
            <ColorIcon name="icon-arrow-right-small-mono" color={colors.orange650} />
          </Box>
        </HStack>
      </VStack>
    </NextLink>
  );
};
